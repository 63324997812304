<template>
  <div class="p-6 ">
      <DialogTitle :label="question.index? question.index: 'Question index goes to here '" align="center"/>
      <div>
        <Content label="QID" :text="question.q_id" />
      </div>
      <div class="md-layout md-gutter" >
        <div class="md-layout-item md-xsmall-size-100 md-large-size-30 md-large-cu_size-38">
          <Content label="Question Section" :text="question.question_type.section" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-large-size-70 md-large-cu_size-58">
          <Content label="Question Category" :text="question.question_type ? question.question_type.title : ''" />
        </div>
      </div>
      <div>
        <div class="my-2" v-if="question.prompt != null">
          <p class="text-uppercase text-victoria mb-2">Prompt</p>
          <div class="text-base line-height-27 rounded bg-wild-sand p-2" v-html="question.prompt"></div>
        </div>
        <div v-if="question.question_type.section == 'SPEAKING' || question.question_type.section == 'LISTENING'">
          <div class="my-2" v-if="question.question_type.short_title != 'DI'">
            <p class="text-uppercase text-victoria mb-2">Audio File</p>
            <AudioFile :src="question.file_path"/>
          </div>
        
          <!-- <div class="my-2" v-if="question.question_type.short_title == 'RA'">
            <p class="text-uppercase text-victoria mb-2">Title</p>
            <p class="p-1 pt-2 pb-4">{{question.title ? question.title : 'No Data'}}</p>
          </div> -->
          <div class="my-2" v-if="question.question_type.short_title != 'RA'">
            <p class="text-uppercase text-victoria mb-2"> Transcript</p>
            <p class="p-1 pt-2 pb-4" v-if="question.transcript != null" v-html="question.transcript"></p>
            <p class="p-1 pt-2 pb-4" v-if="question.transcript == null">No data</p>
          </div>

          <div class="my-2" v-if="question.question_type.short_title == 'DI'">
            <p class="text-uppercase text-victoria mb-2">Image</p>
            <img class="md-image__view" :src="question.file_path"/>
          </div>
        </div>
        <div class="my-2">
          <QuestionList :question="question"/>
        </div>
      </div>
      <div class="md-layout md-gutter" style="max-width:77%">
        <div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-large-size-50">
          <Content label="Preparing Time" :text="perseTime(this.question.answer_time)" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-small-size-50 md-large-size-50">
            <Content label="Answer Time" :text="perseTime(this.question.answer_time)" />
        </div>
      </div>
      <div class="md-layout md-gutter" style="max-width:77%">
        <div class="md-layout-item md-xsmall-size-100 md-large-size-50" v-if="question.source">
          <Content label="Source" :text="question.source" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-large-size-50" v-if="question.frequency">
          <Content label="Frequency" :text="question.frequency" />
        </div>
      </div>
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-xsmall-size-100 md-small-size-25">
          <Content label="Status" content-width="w-full" :text="(question.active == 1) ? 'Published': 'Draft'" text-color="text-green" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-small-size-25">
          <Content label="Created By" content-width="w-full" :text="question.created_by" />
        </div>
        <div class="md-layout-item md-xsmall-size-100 md-small-size-25 md-large-size-25">
            <Content label="Created At" content-width="w-full" :text="question.created_at" />
        </div>
      </div>
      <div class="flex justify-center my-6">
        <span v-if="question.sample_answers.length">
           <md-button @click="SampleAnswerDetail()" class="bg-victoria text-white text-uppercase rounded">Sample Answer</md-button>
        </span>
        <md-button @click="StudyGuideDetail()" class="bg-green text-white text-uppercase rounded">Study Guide</md-button>
        <span v-if="question.explanations.length">
          <md-button :disabled="question.explanations[0].is_default === 0" @click="ExplanationsDetail()" class="bg-victoria text-white text-uppercase rounded">
            <md-tooltip v-if="question.explanations[0].is_default === 0" id="set_explation_tooltip" md-direction="top">Your set explanation has not yet been approved</md-tooltip>
          Explanation</md-button>
        </span>
      </div>
      
      <div class="flex justify-between mt-6">
        <md-button
          :disabled="!question.prev"
          :class="!question.prev ? 'bg-periwinkle-gray': 'bg-victoria'"
          class="ml-0 text-white text-uppercase rounded"
          @click="getAnotherQuestionDetails(question.prev)">Previous</md-button>
        <!-- <md-button class="bg-default text-uppercase rounded" @click="dialog(false)">Back</md-button> -->
        <md-button
          :disabled="!question.next"
          :class="!question.next ? 'bg-periwinkle-gray': 'bg-victoria'"
          class="mr-0 text-white text-uppercase rounded"
          @click="getAnotherQuestionDetails(question.next)">Next</md-button>
        <!-- <md-button class="bg-victoria text-white text-uppercase rounded">Go to edit page</md-button> -->
      </div>
      <StudyGuideDetails  :question="question" :showStudyGuideDialog.sync="showStudyGuideDialog"/>
      <SampleAnswerDetails :question="question" :showSampleAnswerDialog.sync="showSampleAnswerDialog"/>
      <ExplanationDetails :question="question" :showExplanationsDialog.sync="showExplanationsDialog"/>
  </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import AudioFile from "./audio.vue";
import QuestionList from "./questionList.vue"
import { mapActions, mapGetters, mapMutations } from 'vuex';
import StudyGuideDetails from '@/components/molecule/question-and-test-management/material/StudyGuide/DetailsView';
import SampleAnswerDetails from '@/components/molecule/question-and-test-management/material/SampleAnswer/DetailsView';
import ExplanationDetails from '@/components/molecule/question-and-test-management/material/ExplanationDetails'
export default {
  components: {
    DialogTitle,
    Content,
    QuestionList,
    AudioFile,
    StudyGuideDetails,
    SampleAnswerDetails,
    ExplanationDetails
  },
  data() {
      return {
          section: '',
          question_type: [],
          showStudyGuideDialog: false,
          showSampleAnswerDialog: false,
          showExplanationsDialog: false
      }
  },
  computed: {
    ...mapGetters({
      question: "question/getSingleQuestion"
    }),
    usedFor() {
      return this.question.used_for;
    }
  },
  mounted(){
    //console.log(this.question)
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog"
    }),
    ...mapActions({
      actQuestionDetails: "question/actQuestionDetails"
    }),
    perseTime(time) {
      let menute = Math.trunc(parseInt(time) / 60);
      let second = Math.trunc(parseInt(time) % 60);
      return `${menute} M, ${second} S`;
    },
    getAnotherQuestionDetails(questionId) {
      this.actQuestionDetails(questionId)
    },
    StudyGuideDetail(){
      this.showStudyGuideDialog = true;
    },
    SampleAnswerDetail(){
      this.showSampleAnswerDialog = true;
    },
    ExplanationsDetail(){
       this.showExplanationsDialog = true;
    }
  }
}
</script>

<style>
.md-image__view{
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
</style>