<template>
<md-dialog :md-active="showSampleAnswerDialog" class="study_guide">
  <div v-for="(item,index) in question.sample_answers" :key="index" class="archive mt-6">
    <label class="text-uppercase font-bold ml-4 text-lg">Sample Answer <small class="text-victoria w-75p font-bold text-lg line-height-27">{{item.question_type_section}}</small></label>
      <div  class="m-2 md-layout-item md-layout md-gutter mt-2 md-size-100 z-9 align-start">
        <div class="md-layout-item md-size-100 mt-0 pt-0">
          <div class="p-2 b-d m-2 b-d-c br-3">
            <div class="my-2">
              <p class="text-uppercase mb-0 font-bold">THE Sample Answer</p>
              <div  class="text-base line-height-27 rounded pb-2" v-html="item.title"></div>
              <div v-if="item.question_type_section === 'SPEAKING'" class="flex justify-between align-center">
                <div class="mt-2 md-size-100">
                    <span>
                        <button type="button" :class="isActive? 'active': ''" v-on:click="genderHandle('male')"  class="pl-3 pr-3 initial_color">Male</button>
                        <button type="button" :class="isActive? '': 'active'" v-on:click="genderHandle('female')"  class="pl-3 pr-3 initial_color">Female</button>
                    </span>
                    <div class="archive_file_body" v-if="item.resources != null" style="zoom:.9">
                        <span v-if="isActive">
                            <Audio :src="item.resources[0].file_path"/>
                        </span>
                        <span v-if="!isActive">
                            <Audio :src="item.resources[1].file_path"/>
                        </span>
                    </div>
                </div>
            </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  <md-dialog-actions class="md-layout mr-4 m-2 p-1 justify-center">
            <md-button class="md-primary border border-solid border-gray-300" @click="$emit('update:showSampleAnswerDialog', !showSampleAnswerDialog)">Back to question Details</md-button>
  </md-dialog-actions>
</md-dialog>
</template>

<script>
//import VideoThumbnail from "@/components/atom/VideoThumbnail";
import Audio from '@/components/molecule/question/audio.vue'
export default {
  name: "Todo-List",
  components: {
   // VideoThumbnail,
   Audio
  },
  data() {
    return {
       active: false,
       isActive: true,
    }
  },
  props: {
    showSampleAnswerDialog: Boolean,
    question: {
      type: Object
    }
  },
  methods: {
    confirme() {
      this.$emit("update:showSampleAnswerDialog", false);
      this.$emit("confirm");
      this.$emit("success" ,false);
    },
    genderHandle(element) {
        console.log(element)
        if (element == 'male') {
          this.isActive = true;
        } else {
          this.isActive = false;
        }
    }
  },
  created() {
   
  }
};
</script>