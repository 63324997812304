<template>
  <div>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">List</h3>
          </div>
          <div class="flex">
           <md-button
            v-for="(item, index) in buttonFilters"
            :key="index"
            class="rounded"
            @click="onFilteringData(index)"
            :class="[index == currentIndex ? 'bg-victoria text-white': 'outline-gray-400']"
            >
            {{ item.name }}
           </md-button>
          </div>
        </div>
      </template>
      
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-35 md-xlarge-size-40">
                <Card>
                <CardBody class="p-3 relative">
                    <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
                    <SearchBox
                        placeholder="Search"
                        class="w-full p-2 m-1"
                        v-model="keyword"
                        @submit="submitSearchKeyword"
                        />
                    </div>
                    <md-divider></md-divider>
                    <Table :items="questions" hover-action>
                        <template slot="row" slot-scope="{ item }">
                            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
                            <md-table-cell md-sort-by="qId" md-label="Q_ID">{{ item.q_id }}</md-table-cell>
                            <md-table-cell md-sort-by="qId" md-label="Index">{{ item.index }}</md-table-cell>
                           
                            <md-table-cell>
                            <span class="flex action">
                                <router-link :to="{ name: 'master.QATM.questionManagement.questionAnswers', params: {id: item.id} }">
                                    <md-icon class="bg-green text-white rounded-full mx-1 p-1 text-base">send</md-icon>
                                </router-link>
                            </span>
                            </md-table-cell>
                        </template>
                    </Table>
                </CardBody>
                </Card>
            </div>
            
            <div class="md-layout-item md-xsmall-size-100 md-large-size-65 md-xlarge-size-60">
                <Card>
                <CardBody class="p-3 relative">
                    <div class="mb-6 mt-4 ml-4">
                        <h2>Population of canada</h2>
                    </div>
                    <md-divider></md-divider>
                    <Table :items="answers" hover-action>
                        <template slot="row">
                            <md-table-cell md-sort-by="qId" md-label="STD_ID"> AR124 </md-table-cell>
                            <md-table-cell md-sort-by="title" md-label="STD_NAME">Adib Rahman</md-table-cell>
                            <md-table-cell md-sort-by="title" md-label="SUBMITTED_ON">20 Jan, 2019</md-table-cell>
                            <md-table-cell>
                            <span class="flex action">
                                <md-icon @click.native="onShowDetails" class="bg-victoria rounded-full mx-1 p-1 text-white text-base mr-1">visibility</md-icon>
                                <md-icon class="bg-default rounded-full mx-1 p-1 text-gray-700 text-base">cloud_download</md-icon>
                            </span>
                            </md-table-cell>
                        </template>
                    </Table>
                </CardBody>
                </Card>
            </div>
        </div>
        <div @click="showSampleQuestionCreateForm" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
          />
      </div>
    <Dialog>
      <component :questionTypes="questionTypes" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import ChooseSampleAnswer from "@/components/molecule/question/ChooseSampleAnswer";
import cms from "@/data/cms";
import data from '@/data/question-creation/data';
import { query } from "@/utils/queryString";
import { mapMutations, mapGetters ,mapActions } from "vuex";
export default {
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        ChooseSampleAnswer,
  },
  data() {
      return {
          currentIndex: 0,
          component: 'ChooseSampleAnswer',
          questionTypes: '',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
        
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
          buttonFilters : cms.types,
          answers: []
      }
  },
  computed: {
    ...mapGetters({
      questions: "question/getSectionWiseQuestion",
    }),
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return data.readingData.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      },
      questionId() {
        return this.$route.params.id
      }
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
    }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actAnswerListByQuestion: "sampleAnswer/actAnswerListByQuestion",
      actSectionWiseQuestion: "question/actSectionWiseQuestion",
    }),
    showSampleQuestionCreateForm() {
      this.component = 'ChooseSampleAnswer';
      this.dialog(true);
    },
    onShowCreate() {
      console.log('UPDATE: Questio creation Modal replaced to page,');
      this.questionTypes = data.questionTypes;
    },
    submitSearchKeyword() {
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
    onFilteringData(index) {
      this.currentIndex = index;
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
  created() {

    query.set('type', 'question');
    this.actSectionWiseQuestion(`?${query.get()}`);

    this.actAnswerListByQuestion(this.questionId)
    .then(({items}) => this.answers = items);
  }
}
</script>