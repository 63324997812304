<template>
  <div>
      <Tab :items="items" class="my-10" >
        <template v-slot:icon="{ item }">
          <span v-html="item.icon"></span>
        </template>
      </Tab>
      <router-view />
  </div>
</template>

<script>
import Tab from "@/components/atom/Tab";
import data from "@/data/master/qatm/data";
export default {
data() {
    return {
      items: data.questionManagementTab,
      automaticEvaluation: data.automaticEvaluation
    };
  },
  components: {
    Tab,
  }
}
</script>