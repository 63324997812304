<template>
<md-dialog :md-active="showExplanationsDialog" class="explanation">
<!-- {{question.explanations}} -->
  <div v-for="(item,index) in question.explanations" :key="index" v-show="item.is_default === 1">
    <label class="mt-4 text-uppercase font-bold ml-4 text-lg">SET Explanation 
      <small class="text-victoria w-75p font-bold text-lg line-height-27">{{question.question_type.section}}</small>
    </label>
    <div class="md-layout-item md-size-100 mt-0 pt-0">
      <div class="p-2 b-d mb-2 b-d-c br-3 m-4">
        <div class="my-2">
          <p class="text-uppercase mb-0 font-bold">THE Explanation</p>
          <div class="text-base line-height-27 rounded pb-2"  v-html="item.title"></div>
        </div>
        <div class="md-layout md-gutter md-size-70">
          <div class="md-layout-item md-size-100">
            <Gallary :getMaterials="item.materials"/>  
          </div>
        </div>
        <div class="mt-4">
          <div class="">
            <LinkLayout :getLink="item"/>

          </div>
        </div>
        <div class="mt-4">
          <p class="text-uppercase font-bold border-b border-b-solid border-gray-300 mb-4 pb-2">Note</p>
          <div class="text-base line-height-27 rounded pb-2" v-html="item.notes"></div>
        </div>
      </div>
    </div>
    <md-dialog-actions class="md-layout mr-4 m-2 p-1 justify-center">
              <md-button class="md-primary border border-solid border-gray-300" @click="$emit('update:showExplanationsDialog', !showExplanationsDialog)">Back to question Details</md-button>
    </md-dialog-actions>
  </div>
</md-dialog>
</template>

<script>
import Gallary from "./Materials.vue";
import LinkLayout from "./LinkMaterial.vue";
export default {
  name: "Todo-List",
  components: {
     Gallary,
     LinkLayout
  },
  data() {
    return {
       active: false,
       isActive: true,
    }
  },
  props: {
    showExplanationsDialog: Boolean,
    question: {
      type: Object
    }
  },
  methods: {
    confirme() {
      this.$emit("update:showExplanationsDialog", false);
      this.$emit("confirm");
      this.$emit("success" ,false);
    },
  },
  created() {
   
  }
};
</script>
<style scoped>
.explanation img {
    height: 300px !important;
    object-fit: cover;
}
</style>