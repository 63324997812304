<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 16 16" fill="#5B5B5B">
              <path d="M2.5 2C2.222656 2 2 2.222656 2 2.5L2 4.5C2 4.777344 2.222656 5 2.5 5L4.5 5C4.777344 5 5 4.777344 5 4.5L5 2.5C5 2.222656 4.777344 2 4.5 2 Z M 3 3L4 3L4 4L3 4 Z M 7 3L7 4L14 4L14 3 Z M 2.5 6C2.222656 6 2 6.222656 2 6.5L2 8.5C2 8.777344 2.222656 9 2.5 9L4.5 9C4.777344 9 5 8.777344 5 8.5L5 6.5C5 6.222656 4.777344 6 4.5 6 Z M 3 7L4 7L4 8L3 8 Z M 7 7L7 8L14 8L14 7 Z M 2.5 10C2.222656 10 2 10.222656 2 10.5L2 12.5C2 12.777344 2.222656 13 2.5 13L4.5 13C4.777344 13 5 12.777344 5 12.5L5 10.5C5 10.222656 4.777344 10 4.5 10 Z M 3 11L4 11L4 12L3 12 Z M 7 11L7 12L14 12L14 11Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">List</h3>
          </div>
          <div class="flex">
           <md-button
            v-for="(item, index) in buttonFilters"
            :key="index"
            class="rounded"
            @click="onFilteringData(index, item)"
            :class="[index == currentIndex ? 'bg-victoria text-white': 'outline-gray-400']"
            >
            {{ item.name }}
           </md-button>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            v-model="keyword"
            @submit="submitSearchKeyword"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="questions" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-label="SL" md-numeric>{{ item.sl }}</md-table-cell>
            <md-table-cell md-sort-by="q_id" md-label="Q_ID">{{ item.q_id }}</md-table-cell>
            <md-table-cell md-sort-by="index" md-label="INDEX"> {{ item.index }} </md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-label="USED FOR" class="text-victoria ">
              <span @click="showUsedForList(item.id)" class="table-cell-link"> View all </span>
            </md-table-cell>
            <md-table-cell md-label="FREQUENCY">
              <md-icon class="m-0" :class="getIconColor(item.frequency)">{{ (item.frequency) ? 'check': '' }}
                <md-tooltip v-if="item.frequency" md-direction="top">{{ item.frequency }}</md-tooltip>
              </md-icon>
            </md-table-cell>
            <md-table-cell md-sort-by="created_at" md-label="CREATED AT">{{ item.created_at }}</md-table-cell>
            <md-table-cell  md-sort-by="created_by" md-label="CREATED BY" class="text-victoria ">
              <span @click="showCreatedByProfile(item.creator)" class="table-cell-link">{{ item.created_by }}</span>
            </md-table-cell>
            <md-table-cell md-sort-by="active" md-label="STATUS" class="text-green">{{ (item.active == 1) ? 'Published' : 'Draft' }}</md-table-cell>
            <md-table-cell>
              <span class="flex action">
                <md-icon @click.native="onShowDetails" class="bg-victoria rounded-full mx-1 p-1 text-white text-base mr-1">visibility</md-icon>
                <md-icon class="bg-default rounded-full mx-1 p-1 text-gray-700 text-base">delete</md-icon>
                <router-link :to="{ name: 'master.QATM.questionManagement.questionAnswers', params: {id: item.id} }">
                  <md-icon class="bg-green text-white rounded-full mx-1 p-1 text-base">send</md-icon>
                </router-link>
              </span>
            </md-table-cell>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8" v-if="questions.length > 20">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
          />
      </div>
    <Dialog class="md-dailog__teacher">
      <component :questionTypes="questionTypes" :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import QuestionDetails from "@/components/molecule/question/QuestionDetails";
import cms from "@/data/cms";
import data from '@/data/question-creation/data';
import { query } from "@/utils/queryString";
import { find } from 'lodash';
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        QuestionDetails,
  },
  data() {
      return {
          currentIndex: 0,
          component: 'QuestionDetails',
          questionTypes: '',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          },
          buttonFilters : cms.types,
          frequencyStyles: cms.frequency,
      }
  },
  computed: {
    ...mapGetters({
      questions: "question/getSectionWiseQuestion",
    })
  },
  watch: {
    keyword(oldvalue, newValue) {
        query.set('search', oldvalue)
        // For reseting search result
        if(oldvalue.length < newValue.length && oldvalue.length == 0) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
        // For submitting search api after 3 cherecter
        if(oldvalue.length >= 3) {
          this.actSectionWiseQuestion(`?${query.get()}`);
        }
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    ...mapActions({
      actSectionWiseQuestion: "question/actSectionWiseQuestion",
    }),
    onShowDetails() {
      this.component = 'QuestionDetails';
      this.dialog(true);
    },
    onShowCreate() {
      console.log('UPDATE: Questio creation Modal replaced to page,');
      this.questionTypes = data.questionTypes;
     
    },
    submitSearchKeyword() {
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
    onFilteringData(index, item) {
      this.currentIndex = index;
      if(item.name == 'All') {
        query.remove('section');
      }else {
        query.set('section', item.name);
      }
      this.actSectionWiseQuestion(`?${query.get()}`);
    },
    getIconColor(frequency) {
      let frequencyObject = find(this.frequencyStyles, ['id', frequency]);
      return frequencyObject ? frequencyObject.color : ''
    },

    toLower(text){
      return text.toString().toLowerCase()
    },
  },
  created() {
    query.set('type', 'question');
    this.actSectionWiseQuestion(`?${query.get()}`);
  }
}
</script>