<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
              <path d="M6 3 A 1.0001 1.0001 0 0 0 5 4L5 11L4 11 A 1.0001 1.0001 0 0 0 3 12L3 21L5 21L5 13L9 13L9 21L11 21L11 12L11 9L19 9L19 21L21 21L21 8 A 1.0001 1.0001 0 0 0 20 7L17 7L17 4 A 1.0001 1.0001 0 0 0 16 3L6 3 z M 7 5L15 5L15 7L14 7L14 6L12 6L12 7L10 7L10 6L8 6L8 8L9 8L9 10L8 10L8 11L7 11L7 5 z M 12 10L12 12L14 12L14 10L12 10 z M 16 10L16 12L18 12L18 10L16 10 z M 6 14L6 16L8 16L8 14L6 14 z M 12 14L12 16L14 16L14 14L12 14 z M 16 14L16 16L18 16L18 14L16 14 z M 6 18L6 20L8 20L8 18L6 18 z M 12 18L12 20L14 20L14 18L12 18 z M 16 18L16 20L18 20L18 18L16 18 z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Assign To Branch</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="title"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="assignToTrainerOrAdmin" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="qId" md-label="Q_ID">{{ item.qId }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="TITLE">
              {{ item.title }}
              <span v-if="item.bulk" class="ml-2 rounded px-1 bg-tahiti-gold text-white">bulk</span>
            </md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-sort-by="branch" md-label="BRANCH">{{ item.branch }}</md-table-cell>
            <md-table-cell md-sort-by="assignTo" md-label="ASSIGN TO" class="text-victoria">{{ item.assignTo }}</md-table-cell>
            <md-table-cell md-sort-by="assignAt" md-label="ASSIGN AT">{{ item.assignAt }}</md-table-cell>
            <md-table-cell md-sort-by="status" md-label="STATUS" class="text-green">{{ item.status }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onEdit" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { PromptDetails, BranchCreateModal, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { assignToTrainerOrAdmin } from '@/data/master/qatm/question-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        BranchCreateModal
  },
  data() {
      return {
          currentIndex: 0,
          component: 'BranchCreateModal',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    assignToTrainerOrAdmin() {
      if(this.pagination) {
        return assignToTrainerOrAdmin.slice(this.paginate.start, this.paginate.end);
      }
      return assignToTrainerOrAdmin;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return assignToTrainerOrAdmin.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails() {
      this.component = 'PromptDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'BranchCreateModal';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>